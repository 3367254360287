#chat2 .form-control {
    border-color: transparent;
}

    #chat2 .form-control:focus {
        border-color: transparent;
        box-shadow: inset 0px 0px 0px 1px transparent;
    }

.chat-card{
    max-height:360px;
    overflow-y:scroll;
}
.to-message
{
    background:#eee;
    color:#000 !important;
}

.from-message {
    background: #007AFF;
    color: #fff;
}

.to-avatar
{
    text-align:center;
    width:45px;
    height:35px;
    border-radius:20px;
    padding-top:4px;
    background:#eee;
    color:#000;
    border:1px solid #000;
}

.from-avatar {
    text-align: center;
    width: 45px;
    height: 35px;
    border-radius: 20px;
    padding-top: 4px;
    background: #007AFF;
    color: #fff;
    border: 1px solid #000;
}



.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

@font-face {
    font-family: "SF-Pro-Text-Medium"; /*Can be any text*/
    src: local("SF-Pro-Text-Medium"), url("./fonts/SF-Pro-Text-Medium.woff") format("woff");
}

.active-user-switch
{
    margin-top:14px;
    font-size:20px;
}

.invisible{
    display:none;
}

.dashboard-row {
    padding:20px !important;
    padding: 8px;
}

.user-circle-name {
    width: 60px;
    padding: 4px;
    font-size: 30px;
    text-align: center;
    border-radius: 40px;
    border: 1px solid #eee;
    background: #007AFF;
    color: #fff;
}

.orbit-footer-icon {
    color: #000;
    text-align: center;
    font-size: 20px;
    padding-top: 8px;
}

.orbit-row-data
{
    margin-bottom:14px;
}

.label-form {
    padding-top: 6px;
    margin-bottom: 6px;
}

.orbit-footer {
    position: fixed;
    height: 60px;
    z-index: 100;
    bottom: 0;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: #fff;
    border-top: 1px solid #eee;
    display: none;
}

.nav-icon {
    background: #fff;
    color: #444;
    padding: 0px 6px;
    margin: 0px 6px;
    font-size: 30px;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 4px 4px #eee
}

.listing-overlay{
    display:inline-block;
    top:62px;
    position:fixed;
    z-index:10;
    height:100%;
    width:100%;
    background:#fff;
    opacity:1;
}

html {
    /*    position: fixed;
    height: 100%;
    overflow: hidden !important; */
}

body {
    margin: 0 !important;
    width: 100vw !important;
    /*    height: 100vh !important; */
    overflow-y: auto !important;
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch !important;
    font-family: SF-Pro-Text-Medium;
}


.exhibitorContact
{
    font-size:13px;
}

.exhibitorName {
    font-size: 18px;
    display:inline-block;
}

.exhibitorStand
{
    padding-top:0px;
    font-weight:500;
}

.rebookCheckbox {
    position:relative;
    top:-10px;
    text-align: left;
    display:inline-block;
}


.exhibitorCheckin {
    padding-top: 18px;
    text-align: right;
    font-size: 13px;
}

.rebookDetails {
    display:inline-block;
}

.page-header-section {
    margin: 140px 0px 20px 0px;
}

.product-delete
{
    float:right;
}

.showName {
    margin: 6px 0px 0px;
    font-weight: 800;
}

.showTime{
    position:fixed;
    right: 40px;
    top:30px;
    font-size:30px;
    font-weight:800;
}

.showLogo {

    width: 70px;
    height: 70px; 
    margin: 6px 12px 0px 0px;
    padding:0px;
    float: left;
}



table.contract-editable {
    border: 3px solid #fff;
}



.signature-box{
    background:#eee;
    border:1px solid #aaa !important;
    width:250px;
    height:125px;
    padding:10px;
    text-align:center;
    font-weight:800;
    font-size:14px;
    letter-spacing:3px;
}

.contract-editable {
    border: 3px solid #fff;
}

.contract-editable:hover {
    border: 3px solid #1b6ec2;
    cursor: pointer;
}

.contract-menu{
    position:fixed;
    top:62px;
    left:0;
    display:inline-block;
    width:100%;
    background:#fff;
    padding:8px 0px 8px 70px;
    z-index:20;
    box-shadow:4px 4px rgba(0,0,0,0.2);
}

.contract-menu-button
{
    margin-left:14px;
}

td, th {
    border: 1px solid #aaa !important;
    padding: 4px;
}
.contract-page-break
{
    position:relative;
    left:-20px;
    background:#888;
    width:760px;
    height:20px;
    margin-bottom:50px;
    display:inline-block;
    margin:0px !important;
    padding:0px !important;
}

.contract-page {
    display: inline-block;
    width: 100%;
    background: #888;
    height: 100%;
    margin: 0px;
    padding: 80px 0px 80px 0px;
    scrollbar-width: none;
}

.contract-contents {
    /*    box-shadow: rgba(1,1,1,0.1) -10px 15px; */
    width: 760px;
    padding: 20px;
    background: #fff;
    margin: 0 auto;
}

.contract-header {
    margin-top: 180px;
}

.exhibitorModal {
    width: 50%;
    background: white;
    border: 1px solid #ccc;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    border-radius: 20px;
    visibility: visible;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3000;
}

.signatureModal {
    width: 380px;
    background: white;
    border: 1px solid #ccc;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    border-radius: 20px;
    visibility: visible;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3000;
}

.contract-cancelled {
    position: fixed;
    width: 100%;
    top: 116px;
    opacity: 0.8;
    left: 0;
    background: #d70000;
    padding: 2px 0px;
    color: #fff;
    font-weight: 800;
    display: inline-block;
    height: 30px;
    text-align: center;
    letter-spacing: 2px;
    z-index: 30;
}

.contract-signed {
    position: fixed;
    width: 100%;
    top: 116px;
    opacity: 1;
    left: 0;
    background: #00b715;
    padding: 2px 0px;
    color: #fff;
    font-weight: 800;
    display: inline-block;
    height: 30px;
    text-align: center;
    letter-spacing: 2px;
    z-index: 30;
}


.contract-draft
{
    position:fixed;
    width:100%;
    top:116px;
    opacity:0.3;
    left:0;
    background:#666;
    padding: 2px 0px;
    color:#fff;
    font-weight:800;
    display:inline-block;
    height:30px;
    text-align:center;
    letter-spacing:2px;
    z-index:30;
}

.listing-hidden
{
    display:none;
}

.listing-visible
{
    display:block;
}

.listing-contents {
    height: 800px !important;
    overflow: scroll;
    overflow-x: hidden;
}

/**-- Iphone  **/
@media only screen and (max-width: 450px) {


    .contract-signed, .contract-cancelled
    {
        display:none;
    }

    .contract-contents {
        /*    box-shadow: rgba(1,1,1,0.1) -10px 15px; */
        width: 100% !important;
        padding: 20px;
        background: #fff;
        margin: 0 auto;
        overflow-x: hidden;
    }


    .exhibitorModal {
        width: 90% !important;
        bottom: 0px;
        top:initial;
        transform: none;
        left: 5%;
        animation: moveUp ease 0.3s;
    }


    .contract-menu {
        top:50px;
        padding: 12px 0px 8px 6px;
    }

    .sponsor-type {
        display: none;
    }

    .booth-label{
        display:none;
    }
    .booth-number
    {
        font-size:8px;
    }

    .status-badge
    {
        width:20px !important;
        height:8px !important;
    }

    .rebook-status-text {
        display: none;
    }

    .nav-orbit
    {
        font-weight:800;
    }

    .site-logo
    {
        display:none  !important;
    }

    .orbit-footer {
        display: flex;
    }
    .rebookNumber {
        display: none !important;
    }

    .exhibitorTime{
        font-size:14px;
    }
    .exhibitorName{
        font-size:16px;
    }

    /*
    .showTime {
        right: 20px;
        top: 40px;
        font-size: 20px;
        font-weight: 800;
    }

    .showLogo {
        width: 48px;
        height: 48px;
    }
        */

    .rebookNumberCheck {
        font-size: 24px;
        font-weight: 800;
        width: 20px;
        padding: 6px 0px 6px 0px;
        text-align: right;
        display: inline-block;
    }

    .rebookNumber {
        font-size: 16px;
        font-weight: 800;
        width: 20px;
        padding: 0px 0px 6px 0px;
        text-align: right;
        display: inline-block;
    }


}

@media only screen and (max-width: 600px) {
    .page-header-section {
        margin: 105px 0px 20px 0px;
        padding:0px 0px 180px 0px;
    }



    .showName {
        font-size:14px;
    }
}

.reminders-sent {
    background: #1b6ec2;
    color: #fff;
}
.titanium-checkins {
    background: rgba(1, 1, 1, 0.1);
    color: #000;
}
.request-card-body 
{
    max-width: 800px;
    margin: 80px auto !important;
    box-shadow: rgba(0,0,0,0.1) 3px 3px;
}

.diamond-checkins {
    background: #fffbd3;
    color: #000;
}

.card-footer
{
    font-size:14px;
}

.all-checkins {
    background: #E01A76;
    color: #fff;
}
.dashboard-card-title
{
    margin-top:6px;
    font-size:14px;
    text-align:center;
    font-weight:600;
}


.btn-side-logout
{
    position:fixed;
    bottom:40px;
    height:40px;
    left:40px;
    width:100px !important;
}

.btn-side-settings {
    position: fixed;
    bottom: 40px;
    height: 40px;
    left: 160px;
    background:#fff;
    color:#000 !important;

    width: 40px !important;
}


.btn-sidemenu {
    color:#666;
    width: 100%;
    margin-top: 20px;
}
.btn-border
{
    border:1px solid rgba(1, 1, 1, 0.3) !important;
}

.bg-light
{
    color:#000 !important;
    background:#eee !important;
    border:1px solid #aaa;
}

.calendar-color
{
    color:red;
}

.data-row{
    margin-top:20px;
}


.product-row, .notifyalert-row, .listing-row {
    padding: 8px;
    border-bottom: 1px solid #eee;
}

.listing-subhead {
    padding-top:2px;
    margin-right: 14px;
    width: 280px;
    font-size:14px;
    float: left;
    display: inline-block;
}

.product-type, .notifyalert-type {
    margin-top: 6px;
    height: 30px;
    margin-right: 20px;
    width: 80px;
    float: left;
    display: inline-block;
}

.dashboard-card-body {
    font-weight: 800;
    font-size: 50px;
    margin: 0px auto 8px auto;
    text-align: center;
    height: 78px;
}

.dashboard-card {
    min-height: 120px;
    border-radius: 18px;
    margin: 6px 0px;
    box-shadow: 4px 4px rgb(1 1 1 / 10%);
}




.show-name {
    font-size: 13px;
}

@media only screen and (min-width: 900px) {
    .page-header-section {
        margin: 80px 0px 20px 0px;
    }


    .status-signed {
        min-width: 100px;
        background: #10bc00 !important;
        color: #fff !important;
        width: auto !important;
    }

    .status-draft {
        min-width: 100px;
        background: rgba(0, 0, 0, 0.2) !important;
        color: #fff !important;
        width: auto !important;
    }

    .status-cancelled {
        min-width: 100px;
        background: #cc0000 !important;
        color: #fff !important;
        width: auto !important;
    }



    .fixed-page-heading-ignore {
        /*top:55px;*/
        display: none;
    }

}




.checkedIn{
    opacity:0.4;
}


.document-image{
    width:100%;
}

.document-card-footer
{
    position:absolute;
    top:145px;
    z-index:200;
    height:90px;
    width:100%;
    display:inline-block;
    background:#eee;
}

.document-card {
    margin: 0 auto 20px 0;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    height: 200px;
}

.document-body:hover {
    border: 1px solid #1b6ec2;
    cursor: pointer;
}

.new-document {
    margin: 30px 0;
    width: 100%;
    font-size: 40px;
    text-align: center !important;
}

.receptionRow {
    border-bottom: 1px solid #eee;
}
.unchecked {
    background: none;
    padding-top: 6px;
    margin-top: 0px;
    font-size: 28px;
}

.productSearch
{
    margin-top:0px;
    margin-bottom:12px;
}

.input-group-filter {
    position: relative;
    display: flex;
    align-items: stretch;
}

.searchBar
{
    position:fixed;
    width:100%;
    display:inline-block;
    z-index:199;
    background:#fff;
    top:56px;
    left:0;
}

.rebookNumberCheck {
    font-size: 20px;
    font-weight: 800;
    width: 60px;
    padding: 12px 6px 6px 0px;
    text-align: right;
    display: inline-block;
}

.rebookText
{
    padding-top:2px;
}

.rebookNumber {
    z-index:1;
    position:relative;
    top:5px;
    font-size: 16px;
    font-weight: 700;
    width: 60px;
    padding: 12px 6px 6px 0px;
    text-align: right;
    display: inline-block;
}

.fixed-page-heading {
    z-index:100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fafafa;
    box-shadow: rgba(1, 1, 1, 0.1) 0px 4px;
    display: inline-block;
}

@media only screen and (min-width: 900px) {

    .fixed-page-heading-ignore {
        /*top:55px;*/
        display:none;
    }

}


a {
    color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.orbit-row
{
    border-bottom:1px solid #ccc;
    padding:8px;
}

#pageHolder {
    margin-top: 12px;
}

.nav-orbit {
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: inline-block;
    background: #fff !important;
    border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 900px) {

    .contract-menu {
        padding: 12px 0px 8px 6px !important;
    }
    .exhibitorModal {
        width: 80%;
    }


    .page-header-section {
        margin: 80px 0px 20px 0px;
    }

    .searchBar {
        top: 116px;
    }


    .authcontent {
        margin-top: 10px !important;
    }

}

@media only screen and (min-width: 900px) {


    .action-button {
        /* visibility: hidden; */
    }

}

/*
@media only screen and (min-width: 900px) {
    .offline-notice {
        display: none;
    }
}*/
.notification-row {
    border-bottom: 1px solid #eee;
    padding-bottom: 14px;
    padding-top: 6px;
    margin-bottom: 4px;
}

.sync-notify-cloud {
    z-index: 200 !important;
    font-size: 20px;
    color: #000;
    opacity: 0.4;
    padding-bottom: 1px;
    z-index: 100;
    position: fixed !important;
    right: 14px;
    top: 11px;
    width: 30px;
}

.sync-notify {
    z-index:200 !important;
    font-size: 20px;
    color: #0366d6;
    padding-bottom: 1px;
    z-index: 100;
    position: fixed !important;
    right: 28px;
    top: 11px;
}
.pad-badge
{
    padding-right:26px !important;
}



.orbit-user:hover {
    border: 2px solid #007AFF;
    color: #fff !important
}

.orbit-user {
    background: #007AFF;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 20px;
    display: inline-block;
}


.status-badge{
    border:1px solid #ccc !important;
    color:#aaa;
    width:100px;
    margin-right:12px;
}

.status-badge-large {
    border: 1px solid #ccc !important;
    color: #aaa;
    width: 200px;
    margin-top:12px;
    margin-right: 12px;
    margin-left: 12px;
}

.status-signed
{
    background:#10bc00 !important;
    color:#fff !important;
    width:auto !important;
}

.status-draft {
    background: rgba(0, 0, 0, 0.2) !important;
    color: #fff !important;
    width: auto !important;
}

.status-cancelled {
    background: #cc0000 !important;
    color: #fff !important;
    width: auto !important;
}


.sponsor-type-icon-titanium
{
    color:#b1dbff;
}

.sponsor-type-icon-diamond {
    color: #fffbd3;
}

.sponsor-type-icon-platinum {
    color: #aaa;
}

.sponsor-type-icon-gold {
    color: #ffdb1b;
}

.sponsor-type-icon-silver {
    color: #ccc;
    opacity:0.5;
}

.sponsor-type {
    font-size: 16px;
    margin-left:12px;
}

.booth-number {
    font-size: 12px;
    color: #666;
    margin-left: 6px;
}

.dashboard-card-body-activity
{
    padding:8px;
}

.entry-margin
{
    margin-top:12px;
}

.rule-input {
    width: 100%;
    border: 1px solid #eee;
    padding: 4px;
    min-height: 180px !important;
    font-family: Consolas;
}

.popup-background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.8;
    z-index: 499;
}
.dashboard-graph-card
{
    max-width:375px;
}
.popup-background-dark {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    animation: fadeIn ease 0.25s;
    z-index: 499;
}


.page-center {
    z-index: 500;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.timeline-card
{
    background:#f1f1f1;
}

.exhibitor-info
{
    float:right;
    display:inline-block;
    width:60px;
    text-align:center;
}

.timeline-future {
    padding: 6px;
    color: #555;
    font-weight: 100;
    background: #fefefe !important;
}

.timeline-past {
    padding: 6px;
    color: #999;
}

.timeline-now {
    margin-top: 14px;
    margin-bottom: 14px;
    background:#1b6ec2;
    padding: 6px;
    color: #fff;
}

.scrollable-card {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.status-high {
    padding-top: 8px;
    text-align: center;
    color: red;
    font-weight: 800;
    font-size: 12px;
}

.status-low {
    padding-top: 8px;
    text-align: center;
    color: green;
    font-weight: 800;
    font-size: 12px;
}

.sync-spinner {
    font-size: 18px;
    animation: App-logo-spin infinite 1s linear;
    color: #ffffff;
    padding-bottom: 2px;
    z-index: 2000;
    position: fixed !important;
    right: 32px;
    top: 14px;
}

.offline-notice {
    z-index: 200 !important;
    position: fixed !important;
    right: 30px;
    top: 10px;
}

.mobile-heading
{
    display:none;
}


.product-title, .notifyalert-title, .listing-title
{
    font-size:16px;
}

.fixed-page-heading
{
    height:80px;
}

.unlock-icon
{
    text-align:center;
    font-size:80px;
    margin:auto 0px;
}


@media only screen and (max-width: 600px) {



    .contract-contents
    {
        width:100%;
        margin: 0 !important;
    }

    .not-onphone {
        display: none;
    }
    .page-header-section {
        margin: 100px 0px 20px 0px;
    }

    .searchBar {
        top:inherit;
        bottom: 0px;
    }

    .fixed-page-heading-ignore {
        display:none;
    }
    .showLogo
    {
        display:none;
    }

    .showName
    {
        padding-top:8px;
        font-size:16px;
        font-weight:600;
    }

    .showTime{
        top:8px;
        right:60px;
    }

    .pageName{
        display:none;
    }

    .page-header-section {
        margin: 60px 0px 20px 0px;
    }

    .mobile-heading {
        z-index: 100;
        display: inline-block;
        position: fixed;
        width: 100%;
        background: #fff;
        border-bottom:1px solid #ccc;
        top: 0px;
        left: 0px;
        height: 48px;
    }


    

}

.jumbo-logo
{
    opacity:0.3;
    width:256px;
    height:220px;
    margin: 0 auto;
    display:inline-block;
    text-align:center;
}

.nav-orbit {
    margin-bottom: 20px !important;
}

.no-border {
    border: none !important;
}



.action-button {
    z-index:100;
    position: fixed;
    bottom: 70px;
    right: 30px;
}

#pageContent {
    background-color: #fff;
}

.authcontent
{
    margin-top:80px;
}

.anim-in {
    animation: fadeInUp ease 0.5s;
}

.anim-fade {
    animation: fadeIn ease 0.5s;
}

.card-pad {
    max-width: 600px;
}

.btn-pad {
    margin: 20px auto 0 auto !important;
    min-width: 180px;
    max-width: 220px;
    font-weight: 400;
    text-align: center;
    display: inline-block;
}

.input-pad {
    margin: 12px auto !important;
    max-width: 280px !important;
    display: inline-block !important;
    line-height: 30px !important;
}

#pageHolder {
    background-color: #282c34;
}

.Booth-Image {
    padding-top: 40px;
}

.jumbotron
{
    padding-top:80px !important;
}

.btn-jumbotron {
    padding: 0 0 28px 0px;
    margin-top: 30px;
}

.btn-action {
    max-width: 160px;
    /*min-width: 160px; */
    margin: 0px 10px
}

.btn-action-sm {
    min-width: 100px;
    margin: 0px 10px
}

@keyframes moveUp {
    0% {
        transform: translateY(90%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        transform: translateY(10%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.App {
    text-align: center;
}

.App-logo-login {
    height: 20vmin;
    pointer-events: none;
    opacity: 0.3;
}

.inactive
{
    background:#eee;
    color:#999 !important;
}

.App-logo-signin {
    height: 20vmin;
    pointer-events: none;
    animation: App-logo-spin infinite 80s linear;
    opacity: 0.7;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    opacity: 0.5;
}

.App-logo-small {
    height: 30px;
    opacity: 1;
    pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }

    .App-logo-login {
        animation: App-logo-spin infinite 80s linear;
    }

    .App-logo-signin {
        animation: App-logo-spin infinite 80s linear;
    }

    .App-logo-small {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: dimgray;
}

.App-link {
    color: #61dafb;
}
.btn-dialog
{
    text-align:center;
}

.sponsor-type-dialog
{
    font-size:12px;
    text-decoration:underline;
}

.small-text {
    font-size: 12px;
}

.product-row, .pointer-col, .notifyalert-row, .listing-row
{
    cursor:pointer;
}

    .product-row:hover, .notifyalert-row:hover, .listing-row:hover {
        background: #f1f1f1;
    }

.Version-info {
    font-size: 14px;
}

.Caption-info-primary {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 4px;
    color: black;
}

.Caption-info {
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 200;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

jumbo-login {
    padding-top:30px;
    background-color: #fff;
}

.sponsor-type-dialog-text
{
    color:#aaa;
    font-size:12px;
}

/*** BRUNO */
.bruno-canvas {
    opacity: 1;
    touch-action: none;
    cursor: grab;
    width: 100% !important;
    height: 1000px !important;
}

   canvas:active {
        cursor: grabbing;
    }




    .exhibitorModal.off, .signatureModal.off {
        opacity: 0;
        visibility: hidden;
        filter: blur(8px);
        transform: scale(0.33);
        box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
    }

@supports (offset-rotation: 0deg) {
    offset-rotation: 0deg;
    offset-path: path("M 250,100 S -300,500 -700,-200");

    .exhibitorModal.off {
        offset-distance: 100%;
    }
}

@media (prefers-reduced-motion) {
    .exhibitorModal {
        offset-path: none;
    }
}

.exhibitorModal h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
}

.exhibitorModal .content {
    padding: 1rem;
}

.exhibitorModal .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 0.5rem 1rem;
}


#centered-toggle-button {
    position: absolute;
}


.sflogo
{
    max-width:140px;
    margin:10px 0px;
}